const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: '/terms',
    exact: true,
    component: 'Terms',
  },
  {
    path: '/test',
    exact: true,
    component: 'Test',
  },
  {
    path: '/accountDeletion',
    exact: true,
    component: 'AccountDeletion',
  },
  {
    path: "/PrivacyPolicy",
    exact: true,
    component: "Privacy"
  },
];

export default routes;
