import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.css';

import Router from './router';
import i18n from './translation';
import React from 'react';

const App = () => (
  <BrowserRouter>
    <Router />
  </BrowserRouter>
);

const container = document.getElementById('root');
if (container == null) throw new Error(`Couldn't find #root element!`);
const root = createRoot(container);
root.render(<App />);
