import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Arial";
        src: url("/fonts/arial.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Arial GEO Bold";
        src: url("/fonts/G_ari_bd.TTF") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Arial', sans-serif;
    }


    :root {
        --color-white: #fff;
        --color-deep-sapphire: #18216d;
        --color-grey-light: rgb(241, 242, 243);
        --color-persian-indigo: #2e186a;
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: var(--color-white);
        overflow-x: hidden;
    }

    a:hover {
        color: var(--color-deep-sapphire);
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: var(--color-grey-light);
        transition: all 0.3s ease-in-out;
        outline: none;
        width: 100%;
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: var(--color-persian-indigo) 0px 0px 0px 1px;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Arial GEO Bold', serif;
        color: var(--color-deep-sapphire);
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
            /* font-size: 47px; */
        }

        @media only screen and (max-width: 414px) {
            /* font-size: 32px; */
        }
    }

    p {
        color: var(--color-deep-sapphire);
        font-size: 21px;
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: var(--color-persian-indigo);

        :hover {
            color: var(--color-persian-indigo);
        }
    }

    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

`;
